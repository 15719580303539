export const CILogo = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 327 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1357_7656)">
        <path
          d="M264.498 82.7058H266.515C267.161 80.3932 267.99 79.39 269.838 79.3965C272.501 79.3965 280.321 82.9664 292.782 82.999C310.896 82.9664 322.039 71.5792 322.078 57.5081C322.039 44.6617 313.618 40.4208 298.265 37.2027C288.729 35.1767 282.214 34.0563 279.068 32.1606C274.746 29.5874 272.37 25.568 272.429 20.4933C272.37 12.3894 278.533 7.02801 288.304 7.09967C301.882 7.02801 314.447 17.64 314.428 26.1087C314.447 26.239 314.565 26.682 314.571 27.4051H316.595V23.9459C316.582 18.1937 316.823 12.1614 317.169 5.51015H315.296C314.689 7.2495 314.095 8.14197 312.842 8.10288C310.067 8.14197 302.241 4.45482 291.052 4.50042C274.753 4.45482 264.198 14.2785 264.211 28.265C264.204 40.4143 270.601 45.4434 284.995 48.2837C302.724 51.6973 312.796 53.3715 312.848 65.8531C312.796 74.2501 305.57 80.6147 293.65 80.6864C279.61 80.6147 268.232 71.0125 267.24 58.2182H265.223L264.498 82.6993V82.7058Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.158 60.3878C110.456 60.3292 116.977 58.225 122.062 54.7724C129.542 49.4111 133.779 41.3788 133.753 32.8775C133.785 24.3697 129.307 16.1746 121.05 10.696C116.108 7.28242 109.666 5.40627 103.158 5.36719C95.8468 5.40627 89.3257 7.3606 84.2538 10.9826C76.7601 16.2528 72.5237 24.285 72.5628 32.7277C72.5171 41.2876 77.0734 49.4827 85.2655 55.0525C90.3505 58.3749 96.6366 60.3227 103.158 60.3813V60.3878ZM103.158 58.6615C91.2905 58.6941 82.7328 48.7075 82.6676 32.8775C82.7328 17.0345 91.297 7.0479 103.158 7.10002C114.933 7.05442 123.569 16.8781 123.504 32.8775C123.569 48.8639 114.927 58.6941 103.158 58.6615Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.267 60.3878C177.631 60.3292 184.145 58.225 189.171 54.7724C196.711 49.4111 200.954 41.3788 201.006 32.8775C200.954 24.3697 196.476 16.1746 188.16 10.696C183.283 7.28242 176.841 5.40627 170.261 5.36719C163.015 5.40627 156.494 7.3606 151.501 10.9826C143.929 16.2528 139.686 24.285 139.666 32.7277C139.686 41.2876 144.242 49.4827 152.512 55.0525C157.519 58.3749 163.799 60.3227 170.261 60.3813L170.267 60.3878ZM170.267 58.6615C158.465 58.6941 149.901 48.7075 149.921 32.8775C149.908 17.0345 158.465 7.0479 170.267 7.10002C182.108 7.05442 190.744 16.8781 190.758 32.8775C190.744 48.8639 182.102 58.6941 170.267 58.6615Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216.012 34.7454V25.9575C215.947 16.9416 215.947 11.717 216.299 10.1145C216.743 8.25786 217.859 7.95168 221.352 7.80836V6.512C217.624 6.56411 214.276 6.72046 211.541 6.65531C208.616 6.72046 205.346 6.56411 201.586 6.512V7.80836H202.454C206.71 7.87351 207.108 9.41091 207.069 15.0068V38.7713C207.102 48.367 207.023 53.8978 206.638 55.3309C206.305 57.2787 205.111 57.5849 201.586 57.637V58.9334C206.149 58.8161 209.419 58.8161 211.541 58.7901C213.642 58.8161 216.906 58.8161 221.352 58.9334V57.637C218.264 57.5849 216.906 57.3569 216.443 55.7609C215.947 54.2756 215.947 48.9012 216.012 39.6312V36.6085L220.052 33.0061C231.737 46.2889 238.114 53.5069 238.095 55.9042C238.121 57.2722 237.005 57.6566 234.485 57.6305H232.468V58.9269C235.327 58.7314 237.958 58.7314 240.406 58.7836H243.728C247.449 58.7314 251.196 58.7314 254.982 58.9269V57.6305L250.367 57.4872C240.595 44.4389 232.938 34.2959 226.41 27.3841C233.578 20.6157 240.673 13.6974 250.079 8.08848L254.701 7.80185V6.50548C250.967 6.47942 248.337 6.5576 246.763 6.50548C243.63 6.5576 239.798 6.5576 235.51 6.50548V7.80185L239.981 8.08848C239.962 12.0102 231.991 20.9284 216.025 34.7324L216.012 34.7454Z"
          fill="white"
        />
        <path
          d="M256.855 17.0355L257.724 17.7586C262.691 14.6122 265.635 10.1824 266.236 6.37795C266.68 2.74943 265.328 0.436813 262.626 0.0394345C260.263 -0.273257 258.494 1.29671 258.154 3.92853C257.769 6.49521 259.042 8.41695 261.47 8.82736C262.332 8.88599 263.187 8.77525 264.068 8.39741C262.913 11.6155 260.557 14.6187 256.855 17.0355Z"
          fill="white"
        />
        <path
          d="M44.4216 4.21616C18.8008 4.20965 1.65279 18.8345 0.118802 41.231C-1.50657 64.0053 14.2315 80.9949 39.8066 82.7082C56.2953 83.9264 68.3387 78.4999 75.3102 67.0085L73.4368 65.8554C67.3008 75.7443 56.7327 80.9363 44.2845 80.1155C24.5973 78.6953 13.1022 63.5689 14.7015 40.6512C16.2746 18.2808 27.2279 6.78935 44.4281 6.80238C56.3867 6.78284 67.7251 17.3687 67.6599 25.8114C67.7251 25.9612 67.8361 26.4107 67.8035 27.1078H69.6769V23.6486C69.7356 17.9225 69.9576 11.8966 70.2513 5.21287H68.5215C67.9405 6.98478 67.3857 7.87726 66.2107 7.94891C63.5801 7.87726 54.9376 4.19662 44.4216 4.20313V4.21616Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.1683 75.9355V68.7371H91.4673V68.5938H87.5703V68.7371H88.8693V75.9355H87.5703V76.2221H91.4673V75.9355H90.1683Z"
          fill="white"
        />
        <path
          d="M91.6296 76.3877H87.4062V75.7753H88.7052V68.9026H87.4062V68.4336H91.6296V68.9026H90.3306V75.7753H91.6296V76.3877ZM89.0316 76.062H90.0042V68.7528H89.0316V76.062Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.311 68.5964H100.414V68.7397H101.857V75.9381H100.414V76.2247H107.627V72.7656H107.483C107.079 74.9609 106.106 75.9967 104.167 75.9316H103.012V68.7332H104.311V68.5898V68.5964Z"
          fill="white"
        />
        <path
          d="M107.797 76.3877H100.258V75.7753H101.7V68.9026H100.258V68.4336H104.481V68.9026H103.182V75.7753H104.174C104.233 75.7753 104.285 75.7753 104.338 75.7753C106.048 75.7753 106.942 74.8698 107.334 72.7396L107.36 72.6028H107.804V76.3877H107.797ZM104.945 76.062H107.471V73.5735C107.033 75.0913 106.231 75.8796 104.945 76.062ZM102.02 76.062H102.849V68.7528H102.02V76.062Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.618 68.5964H116.721V68.7397H118.02V75.9381H116.721V76.2247H123.79V72.7656H123.646C123.34 74.9609 122.367 75.9967 120.474 75.9316H119.175V68.7332H120.618V68.5898V68.5964Z"
          fill="white"
        />
        <path
          d="M123.96 76.3877H116.564V75.7753H117.863V68.9026H116.564V68.4336H120.788V68.9026H119.345V75.7753H120.481C120.54 75.7753 120.592 75.7753 120.644 75.7753C122.322 75.7753 123.203 74.8372 123.497 72.7461L123.516 72.6093H123.967V76.3942L123.96 76.3877ZM121.238 76.062H123.634V73.6907C123.249 75.1109 122.472 75.8796 121.238 76.062ZM118.19 76.062H119.019V68.7528H118.19V76.062Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.971 68.7371V68.5938H138.086V68.7371H138.373C139.248 68.7371 139.443 69.2256 139.385 70.1767V74.2092C139.45 75.5381 138.771 76.1179 137.074 76.0788C135.84 76.1179 135.311 75.6619 135.344 74.6391V68.7371H136.643V68.5938H132.746V68.7371H134.189V74.4958C134.13 75.6554 135.194 76.3329 137.074 76.3654C138.954 76.3329 139.698 75.6619 139.672 74.2027V70.1702C139.698 69.1996 140.024 68.724 140.827 68.7306H140.971V68.7371Z"
          fill="white"
        />
        <path
          d="M137.073 76.5375C135.852 76.518 134.945 76.2248 134.449 75.6906C134.142 75.3649 133.999 74.961 134.025 74.492V68.9026H132.582V68.4336H136.805V68.9026H135.506V74.6418C135.493 75.0718 135.578 75.3779 135.774 75.5799C136.016 75.8274 136.44 75.9447 137.066 75.9186H137.23C138.033 75.9186 138.574 75.7623 138.881 75.4431C139.136 75.176 139.247 74.7721 139.221 74.2184V70.1795C139.26 69.6127 139.194 69.287 139.031 69.1046C138.901 68.9678 138.692 68.9026 138.392 68.9026H137.928V68.4336H141.14V68.9026H140.833C140.154 68.9026 139.867 69.2739 139.841 70.186V74.2119C139.854 75.0001 139.645 75.5734 139.201 75.9512C138.757 76.329 138.065 76.518 137.086 76.5375H137.073ZM134.351 68.7593V74.505C134.331 74.8894 134.442 75.2151 134.684 75.4757C134.965 75.7753 135.408 75.9903 135.989 76.1076C135.807 76.0359 135.663 75.9382 135.539 75.8144C135.278 75.5473 135.16 75.163 135.18 74.6483V68.7658H134.351V68.7593ZM139.116 68.7593C139.168 68.7919 139.221 68.8375 139.26 68.8831C139.429 69.0655 139.521 69.3261 139.547 69.6974C139.606 69.3261 139.749 68.9678 140.082 68.7593H139.11H139.116Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150.207 76.2266H150.494L151.219 75.2169C151.865 75.9596 152.694 76.3374 153.673 76.37C155.116 76.3374 156.31 75.4189 156.271 74.0639C156.31 73.0281 155.449 72.3897 153.817 71.7578C152.061 70.9565 151.016 70.6373 151.075 69.8881C151.016 69.152 151.806 68.6113 152.949 68.5918C154.293 68.6113 155.338 69.5494 155.834 71.3278H155.977V68.4484L155.109 69.4582C154.456 68.7351 153.771 68.4094 152.798 68.4484C151.499 68.4094 150.501 69.1976 150.488 70.3181C150.501 70.8327 150.742 71.2887 151.212 71.6144C151.787 72.0574 152.714 72.3245 153.667 72.7675C154.953 73.3538 155.599 73.8163 155.54 74.6371C155.599 75.5035 154.75 76.1289 153.667 76.0768C152.054 76.1289 150.86 75.0345 150.494 73.0541H150.207V76.2201V76.2266Z"
          fill="white"
        />
        <path
          d="M153.668 76.5378C152.695 76.5052 151.879 76.1469 151.233 75.4824L150.58 76.3944H150.045V72.9027H150.632L150.659 73.033C150.998 74.8701 152.055 75.9254 153.57 75.9254H153.668C153.72 75.9254 153.753 75.9254 153.792 75.9254C154.334 75.9254 154.81 75.7495 155.097 75.4368C155.3 75.2153 155.398 74.9482 155.384 74.6616C155.43 73.9776 154.999 73.5607 153.602 72.9288C153.283 72.779 152.963 72.6487 152.662 72.5314C152.068 72.2904 151.514 72.0624 151.115 71.7562C150.619 71.4109 150.345 70.9158 150.332 70.3361C150.345 69.1504 151.344 68.297 152.708 68.297H152.813C152.858 68.297 152.917 68.297 152.969 68.297C153.818 68.297 154.49 68.5837 155.11 69.2286L156.142 68.0234V71.5086H155.711L155.678 71.3914C155.208 69.7237 154.236 68.7921 152.95 68.7726C152.297 68.7856 151.749 68.9745 151.455 69.3003C151.298 69.4761 151.226 69.6781 151.239 69.8931C151.2 70.4142 151.788 70.6943 153.106 71.2806C153.348 71.3849 153.609 71.5021 153.89 71.6324C155.234 72.1536 156.488 72.818 156.442 74.0883C156.462 74.6812 156.246 75.2349 155.828 75.6713C155.319 76.2055 154.536 76.5247 153.681 76.5443L153.668 76.5378ZM150.371 76.0687H150.41L151.142 75.046C150.783 74.5769 150.521 73.9776 150.371 73.2675V76.0687ZM151.037 69.3133C150.802 69.5934 150.665 69.9387 150.659 70.3295C150.672 70.7986 150.9 71.2025 151.311 71.4891C151.677 71.7692 152.192 71.9777 152.787 72.2187C153.093 72.3425 153.413 72.4728 153.74 72.6226C155.065 73.2284 155.776 73.7301 155.711 74.6551C155.737 75.0134 155.606 75.3717 155.339 75.6583C155.424 75.5932 155.509 75.515 155.58 75.4368C155.939 75.059 156.116 74.59 156.103 74.0753C156.135 73.1894 155.476 72.5835 153.753 71.9191C153.465 71.7888 153.204 71.6715 152.969 71.5673C151.651 70.981 150.854 70.6292 150.907 69.88C150.894 69.6846 150.933 69.4892 151.031 69.3068L151.037 69.3133ZM155.202 69.613C155.443 69.9322 155.652 70.3165 155.815 70.753V68.8964L155.202 69.613Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.943 76.2221H170.84V75.9355H169.541V68.7371C171.466 68.7371 172.099 69.5123 172.426 71.7597H172.569V68.5938H165.5V71.7597H165.644C165.8 69.6491 166.557 68.7175 168.098 68.7371C168.215 68.724 168.287 68.7371 168.385 68.7371V75.9355H166.943V76.2221Z"
          fill="white"
        />
        <path
          d="M171.005 76.3864H166.781V75.774H168.224V68.8883C168.224 68.8883 168.158 68.8883 168.119 68.8948H168.106H168.054C166.625 68.8948 165.946 69.7547 165.802 71.7677L165.789 71.9175H165.332V68.4258H172.728V71.9175H172.277L172.258 71.7807C171.944 69.6049 171.364 68.9339 169.692 68.8948V75.7675H170.991V76.3799L171.005 76.3864ZM168.55 76.0607H169.379V68.7515H168.55V76.0607ZM170.867 68.7515C171.67 69.0251 172.127 69.6505 172.408 70.784V68.7515H170.867ZM165.658 68.7515V70.556C165.9 69.6179 166.344 69.0251 167.023 68.7515H165.658Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M185.419 68.7428C186.869 68.7428 187.28 69.1597 187.293 70.3258C187.273 71.8566 186.869 72.228 185.269 72.1954H183.97V68.7362H185.413L185.419 68.7428ZM181.379 76.2343H185.419V75.9477H183.977V72.4885H185.419C186.869 72.4234 187.051 72.9641 187.006 74.0715C187.051 75.622 187.404 76.3451 189.023 76.3776C189.401 76.3451 189.682 76.2995 189.891 76.2343V76.091C189.682 76.091 189.473 76.1105 189.316 76.091C188.788 76.1105 188.533 75.889 188.305 75.3744C188.305 75.2116 188.305 74.9966 188.305 74.8011C188.305 74.6643 188.305 74.5731 188.305 74.5145C188.305 74.1627 188.292 73.837 188.161 73.5048C187.913 72.7556 187.195 72.3973 185.994 72.3517C187.613 72.2214 188.572 71.4983 188.592 70.3323C188.572 69.2053 187.613 68.5408 185.994 68.6059H181.379V68.7493H182.822V75.9477H181.379V76.2343Z"
          fill="white"
        />
        <path
          d="M189.034 76.5388H189.028C187.259 76.4997 186.893 75.6463 186.847 74.0764C186.867 73.5357 186.841 73.1383 186.626 72.9103C186.423 72.7018 186.045 72.6237 185.431 72.6497H184.145V75.7831H185.588V76.3955H181.221V75.7831H182.663V68.9104H181.221V68.4414H185.999C186.071 68.4414 186.143 68.4414 186.214 68.4414C187.787 68.4414 188.734 69.1515 188.76 70.3371C188.741 71.3143 188.127 72.0178 187.03 72.337C187.709 72.5259 188.133 72.8973 188.316 73.4575C188.46 73.8158 188.466 74.1741 188.466 74.5193C188.466 74.5715 188.466 74.6692 188.466 74.806C188.466 74.9949 188.466 75.1903 188.466 75.3467C188.669 75.7962 188.865 75.9395 189.256 75.9395H189.308C189.445 75.9525 189.524 75.9395 189.635 75.9395C189.7 75.9395 189.765 75.9395 189.831 75.9395H190.053V76.3629L189.942 76.402C189.733 76.4672 189.459 76.5128 189.041 76.5518H189.034V76.5388ZM186.75 72.5911C186.789 72.6171 186.821 72.6497 186.854 72.6823C187.148 72.9885 187.193 73.464 187.167 74.0764C187.213 75.4835 187.474 76.1414 188.904 76.2066C188.519 76.1023 188.316 75.8157 188.153 75.4314L188.14 75.3662C188.14 75.2034 188.14 74.9884 188.14 74.7929C188.14 74.6561 188.14 74.5649 188.14 74.5063C188.14 74.1806 188.133 73.8679 188.009 73.5552C187.84 73.0471 187.435 72.7344 186.75 72.5911ZM182.983 76.0698H183.812V68.7606H182.983V76.0698ZM184.139 72.0374H185.431C186.802 72.0374 187.115 71.7182 187.135 70.3241C187.122 69.3469 186.874 68.9039 185.444 68.9039H184.139V72.0374ZM186.691 68.7802C187.226 69.0212 187.442 69.5098 187.455 70.3241C187.448 71.184 187.311 71.7051 186.939 72.0113C187.885 71.7312 188.414 71.1384 188.427 70.3241C188.408 69.4511 187.787 68.9039 186.691 68.7802Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M199.564 74.6405L199.851 73.7741H202.88L203.748 75.9369H202.592V76.2235H205.908V75.9369H205.04L202.011 68.4453H201.724L199.27 74.4972C198.95 75.4288 198.597 75.9173 197.684 75.9369V76.2235H200.712V75.9369H200.425C199.812 76.002 199.433 75.7089 199.413 75.2138C199.426 75.0249 199.459 74.8294 199.557 74.6405H199.564ZM199.994 73.4875L201.437 69.885L202.88 73.4875H199.994Z"
          fill="white"
        />
        <path
          d="M206.071 76.3865H202.428V75.7741H203.505L202.768 73.937H199.967L199.713 74.6927C199.621 74.8751 199.595 75.038 199.576 75.2334C199.589 75.6178 199.902 75.8327 200.405 75.7806H200.875V76.393H197.52V75.7806H197.676C198.512 75.7546 198.819 75.3116 199.112 74.4452L201.612 68.2891H202.121L202.161 68.3933L205.15 75.7806H206.071V76.393V76.3865ZM203.969 76.0607H204.915L204.889 75.9956L201.9 68.6083H201.834L201.462 69.5203L203.12 73.6504H203.003L203.969 76.0607ZM198.061 76.0607H199.909C199.517 75.95 199.269 75.6438 199.256 75.2204C199.262 75.1096 199.275 74.9989 199.302 74.8947C199.04 75.5331 198.708 75.9435 198.068 76.0607H198.061ZM200.235 73.3247H202.637L201.436 70.3281L200.235 73.3247Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.145 76.2221H219.042V75.9355H217.599V68.7371C219.557 68.7371 220.191 69.5123 220.484 71.7597H220.628V68.5938H213.559V71.7597H213.702C213.885 69.6491 214.649 68.7175 216.3 68.7371C216.307 68.724 216.379 68.7371 216.444 68.7371V75.9355H215.145V76.2221Z"
          fill="white"
        />
        <path
          d="M219.204 76.3877H214.981V75.7753H216.28V68.9026C214.733 68.9026 214.041 69.743 213.865 71.7755L213.851 71.9253H213.395V68.4336H220.79V71.9253H220.34L220.32 71.782C220.033 69.6062 219.452 68.9352 217.761 68.9026V75.7753H219.204V76.3877ZM216.606 76.062H217.435V68.7528H216.606V76.062ZM218.943 68.7528C219.739 69.0264 220.196 69.6322 220.464 70.7332V68.7528H218.943ZM213.721 68.7528V70.6224C213.969 69.6453 214.439 69.0329 215.164 68.7528H213.721Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.578 76.2273H236.648V73.2047H236.504C236.197 75.0873 235.349 76.0058 233.762 75.9407H232.032V72.1949H232.757C233.782 72.1819 234.213 72.664 234.343 73.7779H234.487V70.3188H234.343C234.258 71.3676 233.625 71.9799 232.613 72.0451H232.039V68.7293H233.769C235.381 68.7293 236.021 69.3937 236.367 71.1787H236.51V68.5859H229.585V68.7293H230.884V75.9277H229.585V76.2143L229.578 76.2273Z"
          fill="white"
        />
        <path
          d="M236.816 76.3877H229.42V75.7753H230.719V68.9026H229.42V68.4336H236.672V71.352H236.228L236.202 71.2218C235.856 69.4694 235.269 68.9026 233.78 68.9026H232.194V71.8927H232.605C233.532 71.8341 234.094 71.2674 234.172 70.3163L234.185 70.1664H234.642V73.9513H234.192L234.172 73.808C234.048 72.7592 233.676 72.3683 232.795 72.3683H232.188V75.7883H233.754C233.806 75.7883 233.859 75.7883 233.904 75.7883C235.269 75.7883 236.039 74.961 236.333 73.1826L236.359 73.0458H236.803V76.3942L236.816 76.3877ZM234.446 76.062H236.489V74.0229C236.13 75.2216 235.458 75.8991 234.446 76.062ZM231.045 76.062H231.874V68.7528H231.045V76.062ZM233.297 72.0751C233.8 72.1794 234.133 72.466 234.329 72.9676V71.098C234.126 71.567 233.774 71.8993 233.297 72.0751ZM234.988 68.7528C235.654 69.0003 236.072 69.5215 236.346 70.4205V68.7528H234.988Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246.319 76.2241H249.635C250.83 76.2046 251.685 76.1264 252.52 75.6508C253.486 74.9212 254.12 73.7356 254.106 72.3415C254.12 70.0615 252.488 68.5306 250.209 68.5958H246.312V68.7391H247.611V75.9375H246.312V76.2241H246.319ZM250.072 68.7326C252.324 68.7326 252.788 69.8074 252.814 72.4783C252.788 73.8138 252.592 74.7779 251.946 75.3577C251.476 75.9114 250.732 75.9961 249.635 75.931H248.767V68.7326H250.066H250.072Z"
          fill="white"
        />
        <path
          d="M249.636 76.3877H246.15V75.7753H247.449V68.9026H246.15V68.4336H250.211C250.256 68.4336 250.302 68.4336 250.354 68.4336C251.477 68.4336 252.489 68.8245 253.194 69.5345C253.905 70.2511 254.277 71.2218 254.271 72.3422C254.284 73.7298 253.683 74.9806 252.619 75.7818C251.771 76.2704 250.955 76.3681 249.636 76.3877ZM247.776 76.062H248.605V68.7528H247.776V76.062ZM252.071 69.1306C252.776 69.7365 252.952 70.8439 252.965 72.479C252.939 74.0295 252.658 74.9285 252.045 75.4822C251.908 75.645 251.751 75.7688 251.555 75.8665C251.862 75.7818 252.149 75.6711 252.43 75.5082C253.389 74.7786 253.944 73.6191 253.938 72.3357C253.938 71.2999 253.605 70.4075 252.952 69.756C252.698 69.502 252.397 69.287 252.064 69.1306H252.071ZM248.925 75.7753H249.63C250.805 75.8405 251.431 75.7102 251.816 75.2542C252.371 74.746 252.619 73.9252 252.645 72.4725C252.619 69.6648 252.071 68.8961 250.08 68.8961H248.931V75.7688L248.925 75.7753Z"
          fill="white"
        />
        <path d="M258.73 64.7344H80.5V65.5487H258.73V64.7344Z" fill="white" />
        <path
          d="M258.729 79.1289H79.2461V79.9432H258.729V79.1289Z"
          fill="white"
        />
        <path
          d="M326.137 7.07216C326.686 7.62588 326.999 8.39458 326.999 9.20889C326.999 9.97758 326.679 10.7463 326.079 11.3391C325.517 11.8928 324.786 12.186 323.944 12.186C323.18 12.186 322.436 11.8668 321.862 11.3065C321.281 10.7333 320.98 9.99713 320.98 9.18934C320.98 8.38156 321.281 7.65194 321.855 7.07216C322.417 6.49889 323.187 6.17969 323.996 6.17969C324.806 6.17969 325.576 6.50541 326.137 7.07868V7.07216ZM322.136 7.32622C321.633 7.83435 321.372 8.48579 321.372 9.18283C321.372 9.87987 321.64 10.5443 322.142 11.0459C322.652 11.5541 323.285 11.8146 323.99 11.8146C324.695 11.8146 325.341 11.5476 325.837 11.0459C326.34 10.5378 326.607 9.87987 326.607 9.18283C326.607 8.48579 326.333 7.78875 325.844 7.30668C325.36 6.82461 324.675 6.53798 323.996 6.53798C323.317 6.53798 322.625 6.82461 322.136 7.32622ZM324.088 7.43045C324.943 7.43045 325.432 7.79526 325.432 8.25127C325.432 8.73333 325.06 8.98088 324.225 9.1242C324.753 9.28054 325.021 9.52809 325.132 9.99713C325.217 10.3424 325.152 10.6486 325.419 10.6486C325.595 10.6486 325.687 10.4597 325.687 10.0948H325.876V10.173C325.876 10.6486 325.635 10.9091 325.197 10.9091C324.923 10.9091 324.714 10.8114 324.623 10.6486C324.479 10.3815 324.564 10.0427 324.492 9.77564C324.388 9.37175 324.12 9.18934 323.657 9.18934H323.533V10.2251C323.533 10.5183 323.663 10.616 324.035 10.6421V10.831H322.39V10.6421C322.769 10.6225 322.9 10.5313 322.9 10.2251V8.04281C322.9 7.74314 322.769 7.64543 322.39 7.61937V7.43045H324.068H324.088ZM323.82 9.01345C324.427 9.01345 324.76 8.73333 324.76 8.28384C324.76 7.89298 324.473 7.60634 323.977 7.60634C323.689 7.60634 323.552 7.70406 323.552 7.89949V9.00042C323.676 9.00694 323.768 9.00694 323.82 9.00694V9.01345Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1357_7656">
          <rect width="327" height="83" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CILogo;
