import { baseImageConfig, getImageUrl } from 'lib/cloudinary';
import { CCLogo } from '../BrandLogos/CCLogo';
import { CILogo } from '../BrandLogos/CILogo';
import styles from './OverlayCard.module.scss';

export interface CardBlockProps {
  headline: string;
  cloudinaryId: string;
  ctaText: string;
  link: string;
}

interface BrandFamilyCardsProps {
  card: CardBlockProps;
  brand: 'cci' | 'cco';
}
const iconMap = {
  cco: CCLogo,
  cci: CILogo,
};

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    width: 375,
    height: 375,
  },
  tablet: {
    ...baseImageConfig,
    width: 670,
    height: 533,
  },
  desktop: {
    ...baseImageConfig,
    width: 670,
    height: 533,
  },
};
type IconType = keyof typeof iconMap;
const BrandFamilyCards: React.FC<BrandFamilyCardsProps> = ({ card, brand }) => {
  const { cloudinaryId, headline, link, ctaText } = card;
  const useIconMap = (type: IconType) => iconMap[type];
  const Icon = useIconMap(brand);
  const mixpanelData = {
    link_name: ctaText,
    destination_URL: link,
  };

  return (
    <a
      href={link}
      className={styles.card}
      data-mx={JSON.stringify(mixpanelData)}
    >
      <picture>
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
          media="(min-width: 1136px)"
        />
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
          media="(min-width:768px)"
        />
        <img
          className={styles.image}
          src={getImageUrl(cloudinaryId, imgConfig.desktop)}
          alt={headline}
        />
      </picture>
      <div className={styles.blurred}>
        <span className={`${brand}`}>
          <Icon />
        </span>
      </div>
      <div className={styles.button}>
        <div className={styles.button__cta}>{ctaText}</div>
      </div>
    </a>
  );
};

export default BrandFamilyCards;
