import styles from './BrandFamilyCards.module.scss';
import OverlayCard, {
  CardBlockProps,
} from './partials/OverlayCard/OverlayCard';

interface FullWidthHeadlineProps {
  brandCards: {
    cciBlock: CardBlockProps;
    ccoBlock: CardBlockProps;
  };
}

const BrandFamilyCards: React.FC<FullWidthHeadlineProps> = ({ brandCards }) => {
  const { cciBlock, ccoBlock } = brandCards;
  return (
    <div className={styles.wrapper} data-testid="brand-family-cards">
      <OverlayCard card={cciBlock} brand="cci"></OverlayCard>
      <OverlayCard card={ccoBlock} brand="cco"></OverlayCard>
    </div>
  );
};

export default BrandFamilyCards;
