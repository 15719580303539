export const CCLogo = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 458 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1357_7639)">
        <path
          d="M344.038 69.8171C343.657 65.3389 353.787 36.1717 353.466 34.1536C353.01 31.2664 348.629 27.4658 344.434 28.8726C341.863 29.7343 329.818 45.1502 324.188 52.4862C324.271 52.2284 324.353 51.9632 324.435 51.7054C326.902 43.957 330.14 36.0686 330.042 34.6618C329.826 31.6567 324.331 29.1156 321.863 30.4046C320.727 31.0012 319.299 38.3961 317.138 46.3655C314.611 55.6975 311.247 66.974 311.546 67.8358C312.099 69.4341 317.4 72.2108 318.731 71.5774C319.082 71.408 319.792 68.7122 320.757 64.9632C322.873 59.6012 342.543 34.4851 344.165 35.6267C345.212 36.3632 335.784 57.2958 335.463 65.6998C335.126 74.472 340.98 77.7496 344.95 78.28C346.902 78.5377 348.501 77.3593 348.501 77.3593C348.501 77.3593 344.419 74.2879 344.038 69.8097V69.8171Z"
          fill="white"
        />
        <path
          d="M395.34 45.5895C395.572 47.0626 398.735 51.2756 401.374 50.6643C404.006 50.053 407.796 34.0331 407.183 32.4938C406.577 30.9617 404.514 28.2586 401.867 28.6858C399.527 29.0615 388.956 41.2807 383.834 47.3278C385.95 41.1997 388.066 35.8008 387.984 34.6592C387.767 31.6541 382.272 29.113 379.805 30.402C378.668 30.9986 377.24 38.3935 375.08 46.3629C372.553 55.6949 368.897 66.9714 369.188 67.8331C369.742 69.4314 375.042 72.2082 376.373 71.5748C376.971 71.2875 378.818 63.6643 381.083 55.9527V55.9821C382.048 52.5351 397.12 38.1946 397.12 38.1946C397.12 38.1946 395.109 44.1091 395.34 45.5821V45.5895Z"
          fill="white"
        />
        <path
          d="M243.692 20.477C245.083 17.0153 245.142 13.8923 243.842 10.6589C242.69 7.86742 239.453 4.33937 236.769 2.21813C232.866 -0.904819 227.356 -0.462893 221.45 1.88668C209.189 6.68158 190.827 26.8776 193.376 50.5354C194.416 59.6833 199.447 66.5994 204.778 69.2804C211.177 72.5139 220.702 72.6244 228.238 66.9382C232.694 63.5943 239.206 55.2198 235.707 47.7513C235.012 46.3003 233.33 45.9615 232.874 47.1841C225.741 63.8079 214.833 71.6227 207.073 65.8261C201.129 61.3626 200.501 44.5178 209.024 28.2329C217.742 11.6091 232.179 2.90311 235.648 6.24702C238.489 8.92804 234.362 17.4351 233.068 23.3496C231.864 28.9252 234.945 30.5309 237.704 32.2028C239.139 33.094 239.879 32.4238 240.133 31.5326C241.09 28.2991 242.294 23.9462 243.685 20.4844L243.692 20.477Z"
          fill="white"
        />
        <path
          d="M339.858 22.3908C345.727 22.1846 355.326 22.0594 364.948 21.971C362.9 29.3217 360.597 37.8214 358.489 45.4226C355.289 56.9863 351.954 67.1064 352.224 67.7693C352.919 69.4855 358.511 72.1002 359.304 71.6214C359.603 71.4447 361.868 61.9064 365.315 51.4107C368.492 41.7398 372.402 30.3676 375.198 21.8826C384.447 21.809 392.356 21.7574 395.085 21.6764C400.558 21.4996 403.07 21.7795 405.014 21.2566C406.95 20.7336 409.029 17.4339 409.014 16.6237C409.014 16.3806 403.623 16.307 397.306 16.3364C393.71 16.3585 385.59 16.3659 376.993 16.3733C378.181 12.6316 378.907 10.0832 378.862 9.48659C378.637 6.37101 372.215 3.16704 370.713 3.46902C370.279 3.55741 368.649 8.87526 366.511 16.388C359.991 16.4027 354.19 16.4322 351.065 16.4838C337.884 16.7121 328.426 17.4486 327.783 17.7064C326.864 18.0452 325.63 21.8237 325.653 22.8033C325.653 22.9653 333.727 22.5823 339.85 22.3908H339.858Z"
          fill="white"
        />
        <path
          d="M133.019 47.7647C136.406 46.4905 140.196 44.6271 142.708 42.0786C146.603 38.1307 146.985 36.2747 146.648 33.822C146.312 31.3693 142.461 26.5228 141.826 26.567C141.19 26.6112 139.134 35.052 134.798 39.9942C133.071 41.9608 129.729 44.3987 127.456 45.9528C131.755 29.3659 136.891 10.584 136.652 8.85313C136.226 5.74491 129.632 2.93868 128.152 3.34378C127.247 3.5942 122.604 27.5245 118.791 46.1812C116.376 57.9732 113.707 67.4084 114.021 68.0565C114.829 69.7358 120.556 71.9897 121.311 71.4667C121.595 71.2679 123.24 62.4956 125.969 51.7421C126.133 51.0866 126.305 50.4237 126.477 49.7534C126.492 49.7534 126.499 49.7534 126.507 49.746C126.626 49.746 126.724 49.7608 126.851 49.7608C133.624 49.7608 129.662 80.9829 146.461 80.9829C152.053 80.9829 151.067 76.6741 151.067 76.6741C137.662 76.6741 142.125 51.4106 133.011 47.7574L133.019 47.7647Z"
          fill="white"
        />
        <path
          d="M306.717 61.4451C308.062 54.5731 314.514 36.9992 314.29 34.2814C314.156 32.6831 308.87 28.5437 306.388 30.5103C305.296 31.372 302.014 39.3267 297.035 46.8984C290.575 56.7386 283.48 67.1901 281.865 66.159C278.994 64.3176 291.009 37.1539 290.613 34.2887C290.164 31.0627 284.856 28.6247 282.852 30.2377C280.729 31.9465 271.982 61.9901 272.318 66.7777C272.535 69.8638 277.492 72.346 282 71.5652C286.03 70.8655 301.625 46.4417 301.625 46.4417C301.625 46.4417 297.82 56.0757 297.723 62.6457C297.566 72.7879 306.709 72.6037 307.165 70.9907C307.165 70.9907 305.199 69.1567 306.702 61.4451H306.717Z"
          fill="white"
        />
        <path
          d="M268.49 33.644C266.434 31.7658 264.655 30.6168 262.232 29.6298C259.003 28.3114 255.601 27.9726 253.126 29.7771C246.51 34.5941 240.573 46.7913 240.185 54.6281C239.841 62.3323 242.928 65.3153 245.351 67.0904C247.504 68.674 250.031 70.0513 252.319 70.7805C256.401 72.0326 259.601 70.8026 261.873 69.2853C270.778 63.3635 273.237 49.7301 272.796 43.1454C272.512 38.7998 271.039 35.9567 268.497 33.644H268.49ZM262.539 52.7278C257.978 64.1811 253.403 68.7845 250.151 66.9873C246.898 65.1901 246.517 58.053 250.689 47.5867C254.861 37.1205 260.221 32.0825 263.698 33.585C267.174 35.0802 266.471 42.8434 262.531 52.7352L262.539 52.7278Z"
          fill="white"
        />
        <path
          d="M152.472 31.2688C154.581 31.6003 163.433 22.6218 161.855 17.8785C160.681 14.3504 154.633 13.7096 153.594 14.645C153.145 15.0428 153.9 17.4365 153.721 20.8541C153.437 26.4003 151.284 31.0847 152.472 31.2688Z"
          fill="white"
        />
        <path
          d="M165.481 28.1797C160.935 28.1797 148.509 39.5004 148.509 47.0499C148.509 53.5905 165.458 59.6743 165.645 62.2743C165.832 64.8743 163.739 65.714 160.823 65.714C153.825 65.714 147.971 57.1848 147.515 57.1848C146.76 57.1848 145.915 58.1423 146.035 59.8069C146.154 61.4715 153.115 71.5842 161.653 71.5842C168.023 71.5842 171.978 66.7599 171.686 62.7089C171.312 57.4352 158.603 54.791 157.518 49.8488C156.053 43.1757 167.589 34.9043 170.191 34.9043C172.793 34.9043 167.058 44.6341 167.432 49.8856C167.522 51.1525 171.507 54.1797 172.718 54.2386C173.652 54.2828 174.251 50.8726 175.379 47.1015C176.396 43.7281 177.712 40.038 177.577 38.1672C177.189 32.6726 168.935 28.1871 165.488 28.1871L165.481 28.1797Z"
          fill="white"
        />
        <path
          d="M450.464 81.1534C445.687 80.0559 439.93 79.1426 433.612 78.4282C434.3 76.2185 447.675 35.6644 447.257 34.567C446.494 32.5415 440.887 29.0429 438.165 30.2729C436.797 30.8916 437.971 32.7477 432.827 41.0338C427.684 49.3199 415.976 66.5477 414.413 66.2752C411.258 65.7228 423.624 36.9607 423.018 34.567C422.413 32.1732 415.617 28.6157 414.615 30.2729C413.613 31.9301 404.29 64.1318 404.417 65.9069C404.544 67.6894 407.505 71.674 411.744 71.674C415.983 71.674 420.865 65.929 425.403 59.3222C430.487 51.9199 435.332 44.7165 435.332 44.7165C435.332 44.7165 431.773 57.8491 425.089 77.6106C391.079 74.878 357.055 77.3602 357.055 87.0457C357.055 91.2809 358.961 94.4112 374.101 98.7936C389.24 103.176 410.847 105.415 417.949 103.021C424.94 100.664 428.409 94.8678 431.594 84.9097C446.337 86.4786 457.701 88.5041 457.925 88.2389C458.501 87.5613 455.481 82.3024 450.464 81.1534ZM404.739 99.3681C386.556 99.3681 363.484 92.0984 363.492 89.8888C363.507 85.58 373.6 83.1789 402.698 83.1789C409.456 83.1789 416.447 83.5619 423.101 84.1143C419.071 95.9358 414.376 99.3681 404.746 99.3681H404.739Z"
          fill="white"
        />
        <path
          d="M50.5553 20.4771C51.9459 17.0153 52.0132 13.8923 50.7049 10.6589C49.5535 7.86742 46.3163 4.33937 43.6323 2.21813C39.7296 -0.904819 34.2196 -0.462893 28.3132 1.88668C16.0521 6.68158 -2.3098 26.8776 0.239624 50.528C1.27883 59.6759 6.3104 66.5921 11.641 69.2731C18.0408 72.5065 27.5656 72.617 35.1018 66.9309C39.5651 63.587 46.0695 55.2125 42.5706 47.7439C41.8753 46.2929 40.1931 45.9541 39.7371 47.1768C32.6047 63.8006 21.6967 71.6153 13.9363 65.8187C7.99258 61.3552 7.36457 44.5105 15.8876 28.2255C24.605 11.5943 39.0493 2.88838 42.5183 6.23229C45.3593 8.91331 41.2323 17.4204 39.9389 23.3348C38.7353 28.9105 41.8155 30.5161 44.5743 32.1881C46.0097 33.0793 46.7499 32.4091 47.0041 31.5178C47.961 28.2844 49.1647 23.9314 50.5553 20.4697V20.4771Z"
          fill="white"
        />
        <path
          d="M75.3472 33.6458C73.3061 31.937 71.3324 30.7511 69.0895 29.6316C64.7607 27.4588 61.5907 28.3132 58.8992 30.4197C52.4247 35.4208 47.0119 46.6163 46.6231 54.4531C46.2792 62.1574 49.7856 65.3171 52.2005 67.0922C54.3536 68.6758 56.8806 70.0531 59.1684 70.7823C63.2505 72.0344 66.4503 71.5704 68.7231 70.0605C77.6275 64.1313 80.0872 50.5053 79.6461 43.9205C79.362 39.5749 77.9564 35.8849 75.3472 33.6531V33.6458ZM69.396 52.7296C64.8354 64.1829 60.2599 68.7863 57.0077 66.9891C53.7555 65.1919 53.3742 58.0548 57.546 47.5885C61.7178 37.1222 67.0784 32.0843 70.5548 33.5868C74.0313 35.082 73.3286 42.8452 69.3885 52.737L69.396 52.7296Z"
          fill="white"
        />
        <path
          d="M110.066 33.6458C108.025 31.937 106.051 30.7511 103.808 29.6316C99.4794 27.4588 96.3095 28.3132 93.618 30.4197C87.1435 35.4208 81.7306 46.6163 81.3419 54.4531C80.9979 62.1574 84.5043 65.3171 86.9192 67.0922C89.0724 68.6758 91.5994 70.0531 93.8871 70.7823C97.9692 72.0344 101.169 71.5704 103.442 70.0605C112.346 64.1313 114.806 50.5053 114.365 43.9205C114.081 39.5749 112.675 35.8849 110.058 33.6531L110.066 33.6458ZM104.115 52.7296C99.5542 64.1829 94.9787 68.7863 91.7265 66.9891C88.4743 65.1919 88.093 58.0548 92.2648 47.5885C96.4366 37.1222 101.797 32.0843 105.274 33.5868C108.75 35.082 108.047 42.8452 104.107 52.737L104.115 52.7296Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1357_7639">
          <rect width="458" height="104" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CCLogo;
